<template>
    <div>
        <vx-card actionable class="cardx">
            <vs-row type="flex">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
                    <b class="card-title-of-sr-spoc">Onboarding SR SPOC Management</b>
                </vs-col>
                <!--City -->
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
                    <v-select class="selectExample" placeholder="Onboarding City Classification" v-model="selected_city_classification"
                        :options="cities" style="width:50%">
                    </v-select>
                </vs-col>
            </vs-row>
            <!-- <vx-card actionable class="cardx"> -->
            <div v-if="this.table_data.length>0">
                <vs-table stripe :data="table_data">
                    <template slot="thead">
                        <vs-th>SPOC Name</vs-th>
                        <vs-th>SPOC City</vs-th>
                        <vs-th>Onboarding City Classification</vs-th>
                        <vs-th>Status</vs-th>
                        <vs-th v-if="admin_id == 611 || admin_id == 39 || admin_id == 132 || admin_id == 937" 
                            class="actions-of-sr-spoc">Actions</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>{{ tr.first_name +" "+ tr.last_name}}</vs-td>
                            <vs-td>{{tr.city === null ? "--" : tr.city}}</vs-td>
                            <vs-td v-if="tr.sr_city_classification !== '' && tr.sr_city_classification !== null">{{tr.sr_city_classification}}</vs-td>
                            <vs-td v-else>--</vs-td>
                            <vs-td v-if="tr.onboarding_spoc == 1" style="color:green">Active</vs-td>
                            <vs-td v-else style="color:red">In-active</vs-td>
                            <vs-td v-if="admin_id == 611 || admin_id == 39 || admin_id == 132 || admin_id == 937 || admin_id == 742 || admin_id == 1428 || admin_id == 794">
                                <!-- <div style="display:flex; justify-content:center"> -->
                                <vs-row>
                                    <vs-col vs-offset="4" vs-w="2">
                                        <vs-button type="flat" icon="edit" size="small" color="success"
                                            @click="updatePopup(), selected_spoc =tr.id,selected_spoc_name = tr.first_name+tr.last_name,show_onboarding_city=tr.sr_city_classification">
                                        </vs-button>
                                    </vs-col>
                                    <vs-col vs-w="6">
                                        <vs-button v-if="tr.onboarding_spoc == 1" type="flat" icon="delete" size="small"
                                            color="danger"
                                            @click="deletePopupOpen = true, selected_spoc = tr.id,selected_spoc_name = `${tr.first_name}  ${tr.last_name}`, selected_onboarding_city = tr.sr_city_classification">
                                        </vs-button>
                                    </vs-col>
                                </vs-row>
                                <!-- </div> -->
                            </vs-td>

                        </vs-tr>
                    </template>
                </vs-table>
            </div>
            <vs-row v-if="this.table_data.length<1">
                <vs-col vs-offset="1" vs-w="10" class="my-2" vs-type="flex" vs-justify="center" vs-align="center">
                    <vs-icon icon="hourglass_empty" color="grey" size="40px"></vs-icon>
                    <h5 style="margin-left:3%">No spocs found !</h5>
                </vs-col>
            </vs-row>
            <!-- </vx-card> -->
        </vx-card>
        <vs-popup class="holamundo" title="Confirmation" :active.sync="deletePopupOpen">
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                    <b>
                        Do you really want to remove &nbsp;"{{selected_spoc_name}}"&nbsp; from &nbsp; "{{selected_onboarding_city}}" onboarding?
                    </b>
                </vs-col>
            </vs-row>
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                    <vs-button color="danger" type="border" @click="deletePopupOpen = false">Back</vs-button>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                    <vs-button color="success" type="border" @click="deleteSpoc(selected_spoc)">Remove</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
        <vs-popup class="holamundo" title="Confirmation" :active.sync="updatePopupOpen">
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                    <b>
                        <vs-icon color="danger" style="margin-right:5px"></vs-icon>Do you really
                        want to Update &nbsp;"{{selected_spoc_name}}" ?
                    </b>
                </vs-col>
            </vs-row>
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
                    <vs-button color="danger" type="border" @click="updatePopupOpen = false">Cancel</vs-button>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                    <vs-button color="success" type="border" @click="updateSpoc(selected_spoc)">Yes update</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
        <vs-popup class="holamundo sr-spoc-management" :title="`Update onboarding city classification : ${selected_spoc_name}`"
            :active.sync="updateSpocPopup">

            <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <h6 style="color:#7367f0;">Onboarding City Classification :</h6>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="8">
                    <v-select class="selectExample city-filter" :placeholder="showOnboardingCity()"
                        multiple v-model="selected_city_for_update" :options="update_city_classification"
                        style="width:275px;margin-left:4%;z-index:5000;position: fixed;">
                    </v-select>
                </vs-col>

                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12" style="margin: 4% 2% 0 0">
                    <vs-button color="success" type="border" @click="checkFeildsForUpdate()">
                        Update</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
    </div>
</template>
<script>
import axios from 'axios';
import constants from "../../../constants.json";
import vSelect from "vue-select";
export default {
    components: {
        "v-select": vSelect,
    },
    data() {
        return {
            show_onboarding_city: "Select City",
            selected_spoc: null,
            onboarding_spoc: '',
            selected_city_for_update: "",
            selected_spoc_name: "",
            selected_onboarding_city:"",
            deletePopupOpen: false,
            updatePopupOpen: false,
            updateSpocPopup: false,
            cities: [],
            update_city_classification: [],
            selected_city_classification: "",
            table_data: [],
            onboarding_spoc_array: ['Active', 'In-active'],
            admin_id: '',
        }
    },
    watch: {
        selected_city_classification: function (val) {
            console.log("this.table_data", this.total)
            console.log("this.table_data", val)
            if (val !== '' && val !== null && val !== 'All') {
                console.log("hi");
                this.table_data = []
                // for (let i = 0; i < this.total.length; i++) {
                //     console.log(this.total[i].sr_city.toLowerCase(),val.toLowerCase())
                //     if (this.total[i].sr_city == val) {
                //         this.table_data.push(this.total[i])
                //         console.log("this.table_data",this.table_data)
                //     }
                // }
                for (let i = 0; i < this.total.length; i++) {
                    // const element = this.total[i];
                    // if (this.total[i].sr_city_classification == val) {
                    //     this.table_data.push(this.total[i])
                    //     console.log("this.table_data", this.table_data)
                    // }
                    if(this.total[i].sr_city_classification != null){
                    if (this.total[i].sr_city_classification.includes(val)) {
                        this.table_data.push(this.total[i])
                        console.log("this.table_data", this.table_data)
                    }
                }
                }
            } else if (val == null || val == 'All') {
                console.log("bye");
                this.table_data = this.total
            }
        }
    },
    mounted() {
        // this.getTeams()
        this.getActiveOnboardingSrSpocs()
        this.admin_id = localStorage.getItem("spoc_id");
        console.log("admin", this.admin_id);
        this.getDistinctCityClassification()
    },
    methods: {
        getDistinctCityClassification(){
            let url = "https://mfcomms.2x2.ninja/api/getDistinctCityClassification"
            axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, }).then((response) => {
                console.log("responseee", response.data);
                this.update_city_classification = response.data;
                this.cities = response.data;
            })
            .catch((error) => {
                console.log("error", error);
            });
        },
        getTeams() {
            let url = `${constants.SERVER_API}getAllActiveSpocsForDashbaord`;
            axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, }).then((response) => {
                let sr_spocs = [];
                for (let i = 0; i < response.data.length; i++) {
                    const element = response.data[i];
                    if (element.team == 'SR' && element.sub_team == 'SR') {
                        sr_spocs.push(element)
                    }
                }
                this.cities = [
                    ...new Set(sr_spocs.map((item) => item.city)),
                ];
                this.update_cities = [...new Set(sr_spocs.map((item) => item.city)),];
                this.cities.splice(0, 0, 'All')
            })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        getActiveOnboardingSrSpocs() {
            this.table_data = []
            this.total = []
            let url = `${constants.MILES_GOOGLE_INVITE}getActiveOnboardingSrSpocs`;
            axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, }).then((response) => {
                console.log("getActiveOnboardingSrSpocs", response.data);
                this.total = response.data
                this.table_data = response.data
                // this.sortAscending(response.data);
            })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        sortAscending(val) {
            let data = val;
            const sorter = (sortBy) => (a, b) => a[sortBy] > b[sortBy] ? 1 : -1;
            data.sort(sorter('first_name'));
            this.table_data = data;
        },
        deleteSpoc(id) {
            let url = `${constants.MILES_GOOGLE_INVITE}deleteOnboardingSpoc?sr_id=${id}`;
            axios.delete(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, }).then((response) => {
                console.log("deleted successfully", response.data);
                if (response.data == "deleted successfully") {
                    this.$vs.notify({
                        title: 'Deleted',
                        text: `${response.data}`,
                        color: 'danger'
                    })
                }
                this.getActiveOnboardingSrSpocs()
                this.clearAllFeilds()
            })
                .catch((error) => {
                    console.log("error", error);
                    this.clearAllFeilds()
                });
        },
        updateSpoc(id) {
            // let status = 0
            // if (this.onboarding_spoc == 'active') {
            //     status = 1
            // }
            let url = `${constants.MILES_GOOGLE_INVITE}updateOnboardingSrSpocs`;
            let obj = {
                'sr_id': id,
                'sr_city_classification': this.selected_city_for_update.toString(),
            }
                        axios.put(url, obj, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, }).then((response) => {
                console.log("updated successfully", response.data);
                if (response.data == "updated successfully") {
                    this.$vs.notify({
                        title: `${response.data}`,
                        text: `${this.selected_spoc_name} has been updated with new city ${this.selected_city_for_update}`,
                        time: 4000,
                        color: 'success'
                    })
                }
                this.getActiveOnboardingSrSpocs()
                this.clearAllFeilds()
            })
                .catch((error) => {
                    console.log("error", error);
                    this.clearAllFeilds()
                });
        },
        updatePopup(){
            this.updateSpocPopup = true;
            this.selected_city_for_update = ""
        },
        checkFeildsForUpdate() {
            if (this.selected_city_for_update !== '') {
                this.updatePopupOpen = true
                this.updateSpocPopup = false
            } else {
                this.$vs.notify({
                    title: 'Feild is required',
                    text: 'required feilds must be filled in',
                    color: 'danger'
                })
                this.clearAllFeilds()
            }
        },
        clearAllFeilds() {
            this.selected_spoc = null,
                this.onboarding_spoc = null,
                this.selected_city_for_update = "",
                this.selected_spoc_name = "",
                this.selected_onboarding_city = '',
                this.deletePopupOpen = false,
                this.updatePopupOpen = false,
                this.updateSpocPopup = false,
                this.selected_city_classification = ""
        },
        showOnboardingCity() {
            let val = 'Select Onboarding City'
            if (this.show_onboarding_city !== null) {
                val = this.show_onboarding_city
            }
            return val
        }
    },
}
</script>
<style >
.card-title-of-sr-spoc {
    font-family: sans-serif;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
}
.actions-of-sr-spoc {
    display: flex !important;
    justify-content: center !important;
}
.sr-spoc-management .vs-popup {
    width: 500px;
}
.city-filter>.vs__dropdown-menu {
    height: 250px;
}
</style>